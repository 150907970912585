<template>
    <div class="article-page" id="media" data-nav-section>
        <div class="article-page__wrap" v-if="currentArticle">
            <div class="wrapper">
                <nav class="article-page__breadcrumbs">
                    <router-link to="/#media" class="breadcrumbs__link">Медиа</router-link>
                    <span class="breadcrumbs__link">{{ currentArticle?.name }}</span>
                </nav>

                <RouterLink :to="previousMediaUrl" class="article-page__control article-page__control--prev"
                    v-if="previousMediaUrl">
                    Прошлая статья
                </RouterLink>
                <RouterLink :to="nextMediaUrl" class="article-page__control article-page__control--next" v-if="nextMediaUrl">
                    Следующая статья
                </RouterLink>

                <div class="article-page__content">
                    <div class="article-page__date" v-if="currentArticle.createDate">
                        {{ getFormattedDate(currentArticle.createDate) }}
                    </div>

                    <div ref="articleWrap" class="article-page__article" v-html="currentArticle.description"></div>
                </div>
            </div>

            <ArticleAttachments class="article-page__article-attachments" v-if="currentArticle.files_ids?.length"
                :attachments="currentArticle.files_ids" />

        </div>

        <nav class="article-page__breadcrumbs article-page__breadcrumbs--bottom">
            <div class="wrapper">
                <router-link to="/#media" class="breadcrumbs__link">Медиа</router-link>
                <span class="breadcrumbs__link">{{ currentArticle?.name }}</span>
            </div>
        </nav>
    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { RouterLink, useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref, watch } from 'vue';

import ArticleAttachments from '@/components/Article/Attachments'
import moment from 'moment-with-locales-es6';

const store = useStore()
const route = useRoute()
const router = useRouter()

const articleWrap = ref()
const currentArticle = ref()

onMounted(() => {
    currentArticle.value = store.getters['materials/getMediaFromCacheById'](route.params.id)


    if (!currentArticle.value) {
        router.push('/')
    }
})

watch(() => route.params, () => {
    currentArticle.value = store.getters['materials/getMediaFromCacheById'](route.params.id)

    if (!currentArticle.value) {
        router.push('/')
    }
})

const previousMediaUrl = computed(() => {
    const articles = store.state.materials.media

    const currentArticleIndex = articles.findIndex(el => el.id === route.params.id)

    if (currentArticleIndex === 0) {
        return false
    }

    return `/media/${articles[currentArticleIndex - 1].id}`
})

const nextMediaUrl = computed(() => {
    const articles = store.state.materials.media

    const currentArticleIndex = articles.findIndex(el => el.id === route.params.id)

    if (currentArticleIndex === articles.length - 1) {
        return false
    }

    return `/media/${articles[currentArticleIndex + 1].id}`
})



const getFormattedDate = (date) => {
    moment.locale('ru')
    const momentDate = moment(date).clone();

    return momentDate.format('D MMMM YYYY года')
}

</script>

<style lang="scss" scoped>
.article-page {
    &__wrap {
        padding: 100px 0;
        position: relative;

        @media (max-width: 600px) {
            padding: 40px 0;
        }
    }

    &__img {
        max-height: 60vh;
        margin: auto;
        display: block;
        margin-bottom: 20px;
    }

    &__breadcrumbs {
        margin-bottom: 60px;

        &--bottom {
            padding: 20px 0;
            border-top: 1px solid rgba(#16171E, 0.2);
            border-bottom: 1px solid rgba(#16171E, 0.2);
            margin-bottom: 0;
        }
    }


    &__nav {
        background: #FFFFFF;
        border-radius: 10px;
        overflow: hidden;
        position: sticky;
        top: 20px;

        &-title {
            font-size: 18px;
            padding: 16px 24px;
        }

        &-list {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        &-link {
            display: flex;
            text-decoration: none;
            padding: 20px 25px;
            font-size: 12px;
            line-height: 150%;
            border-left: 3px solid transparent;
            transition: 500ms;

            &--active {
                background: rgba(18, 116, 224, 0.1);
                transition: 500ms;
                border-color: #1274E0;
                box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);

                @media (max-width: 991px) {
                    border-color: transparent;
                    background: transparent;
                    box-shadow: none;
                }
            }
        }
    }

    &__control {
        position: fixed;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 100lvh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-weight: 700;
        color: #1274E0;

        @media (max-width: 1700px) {
            position: static;
            height: auto;
            display: inline-block;
            width: 50%;
            margin-bottom: 40px;
        }

        &--prev {
            left: 40px;

            @media (max-width: 1700px) {
                text-align: left;
        }

        }

        &--next {
            right: 40px;

            @media (max-width: 1700px) {
                text-align: right;
        }

        }
    }

    &__meta {
        display: flex;
        align-items: center;
        gap: 15px;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 30px;

        &-link {
            padding: 12px 20px;
            background: rgba(18, 116, 224, 0.1);
            border-radius: 5px;
            text-decoration: none;
            color: #1274E0;
            display: flex;
            align-items: center;
            gap: 5px;


            &::after {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg width='5' height='9' viewBox='0 0 5 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 8L4 4.5L1 1' stroke='%231274E0' stroke-linecap='round'/%3E%3C/svg%3E%0A");
                width: 10px;
                height: 10px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    &__article {
        font-size: 16px;
        margin-bottom: 100px;
        text-align: justify;

        &:deep(h1) {
            font-size: 40px;
            line-height: 120%;
        }

        &:deep(h2) {
            font-size: 26px;
            line-height: 120%;
        }
    }
}
</style>